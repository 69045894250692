import { DeviceService } from './../../../shared/services/device.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalOnboardComponent } from '../../modals/onboard/modal-onboard.component';
import { SearchPageService } from '../../services';
import { CompanyService, AlertMessageService, ExternalService } from 'src/app/shared/services';

@Component({
    selector: 'app-search-page',
    templateUrl: './search-page.component.html',
    styleUrls: ['./search-page.component.scss']
})
export class SearchPageComponent implements OnInit {
    token;
    loading: boolean = false
    onboardType = null;
    patientName = '';

    constructor (
        public searchPageService: SearchPageService,
        private route: ActivatedRoute,
        private router: Router,
        private bsModalService: BsModalService,
        private deviceService: DeviceService,
        private companyService: CompanyService,
        private alertMessageService: AlertMessageService,
        private externalService: ExternalService
    ) { }

    ngOnInit() {
        this.token = this.route.snapshot.queryParams.token;
        this.getCompanyLogo();

        if (!this.token) {
            this.searchPageService.item = null;

            return;
        }

        if (this.deviceService.isMobile()) {
            this.router.navigate([`/mobile/card-info`], {
                queryParams: {
                    token: this.token
                }
            });

            return;
        }

        this.setDefaultStyle();
        this.getOnboardType();
    }

    getOnboardType() {
        const data = {
            token: this.token
        };

        this.loading = true;

        this.externalService.getOnboardType(data, (error, resp) => {
            this.loading = false;

            if (error) {
                this.alertMessageService.open(error.message, null, 'error');
                // this.hideModal('');
                return;
            }

            this.onboardType = resp.type;
            this.patientName = resp.patient_name;
            this.searchPageService.list();
        });
    }

    getCompanyLogo() {
        const data = {
            token: this.token
        };

        this.companyService.getLogo(data, (error, resp) => {
            if (error) {
                this.alertMessageService.open(error.message, null, 'error');
                localStorage.removeItem('company_logo');
                return;
            }

            this.companyService.setCompanyLogo(resp);
        });
    }

    openOnboardModal() {
        const modal = this.bsModalService.show(ModalOnboardComponent, {
            class: 'modal-onboard',
            backdrop: 'static',
            ignoreBackdropClick: true
        });

        modal.content.onClose.subscribe((resp) => {
            this.onboardType = resp;

            if (!this.onboardType) {
                this.onboardType = 'not available';
                return;
            }

            this.searchPageService.list();
        });
    }

    setDefaultStyle() {
        document.body.style.backgroundColor = '#F2F5F7';
    }
}
