import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeviceService, ExternalService, AlertMessageService, CepService } from '../../../shared/services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IsValidCPF, IsValidDate, isBeforeDate, ValidateCPF } from 'src/app/shared/helpers';
import * as moment from 'moment-timezone';
import { CompilePipeMetadata } from '@angular/compiler';

@Component({
    selector: 'app-agilize-attendance-page',
    templateUrl: './agilize-attendance-page.component.html',
    styleUrls: ['./agilize-attendance-page.component.scss']
})
export class AgilizeAttendancePageComponent implements OnInit {
    token = null;
    textAreaValue = null;
    rate = 0;
    companyName = null;
    formStep: boolean = true;
    loading: boolean = true;
    loadingAddress: boolean = false;
    isValidToken: boolean = true;
    doctor = null
    decryptedData = null;
    patient = {
        name: null,
        born: null,
        email: null,
        contact_cellphone: null,
        cpf: null,
        address_cep: null,
        address_address: null,
        address_district: null,
        address_number: null,
        address_complement: null,
        address_state: null,
        address_city: null,
        alergic: null,
        allergy: null
    };
    isWeb: boolean = true;
    alergic = 'no';
    addressFound = null;
    cepInfoByKey = {};
    step = 1;
    errors = {
        cpf: false,
        born: false
    };

    constructor (
        private route: ActivatedRoute,
        private externalService: ExternalService,
        private deviceService: DeviceService,
        private alertMessageService: AlertMessageService,
        private cepService: CepService
    ) {}

    changeStep(type) {
        if (type === 'increase') {
            this.step++;
        } else {
            this.step--;
        }
    }

    changeBorn() {
        const date = this.patient.born;
        const now = moment();

        if (!date || moment(date, 'DDMMYYYY').isBefore(now)) {
            this.errors.born = false;
            return;
        }

        this.errors.born = true;
    }

    changeCep() {
        const cepValue = this.patient.address_cep;

        if (!this.cepService.isValidCep(cepValue)) {
            this.addressFound = null;
            this.cleanAddressValues();
            return;
        }

        if (this.cepInfoByKey[cepValue]) {
            this.addressFound = {
                ...this.cepInfoByKey[cepValue]
            };

            return this.completeAddressValues(this.cepInfoByKey[cepValue]);
        }

        this.loadingAddress = true;

        this.cepService.getAddressByCep(cepValue).subscribe(cep => {
            this.loadingAddress = false;

            if (cep['erro']) {
                this.cleanAddressValues();
                this.alertMessageService.open('O endereço do cep digitado não foi encontrado!', null, 'warning');

                return;
            }

            this.cepInfoByKey[cepValue] = {
                address_address: cep['logradouro'],
                address_district: cep['bairro'],
                address_complement: cep['complemento'],
                address_city: cep['localidade'],
                address_state: this.cepService.parseStateName(cep['uf'])
            };

            this.addressFound = {
                ...this.cepInfoByKey[cepValue]
            };

            this.completeAddressValues(cep);
        });
    }

    ngOnInit() {
        this.token = this.route.snapshot.queryParams.token;
        this.isWeb = this.deviceService.isWeb();

        if (this.deviceService.isWeb()) {
            this.setBodyStyle();
        }

        if (!this.token) {
            this.loading = false;
            this.isValidToken = false;

            return;
        }

        this.validateToken();
    }

    cleanAddressValues() {
        this.patient.address_address = null;
        this.patient.address_district = null;
        this.patient.address_number = null;
        this.patient.address_complement = null;
        this.patient.address_state = null;
        this.patient.address_city = null;
        this.addressFound = null;
    }

    completeAddressValues(cep) {
        this.patient.address_address = cep['logradouro'];
        this.patient.address_district = cep['bairro'];
        this.patient.address_city = cep['localidade'];
        this.patient.address_state = this.cepService.parseStateName(cep['uf']);
    }

    changeCpf() {
        if (!this.patient.cpf) {
            this.errors.cpf = false;
            return;
        }

        this.errors.cpf = !ValidateCPF(this.patient.cpf);
    };

    validateToken() {
        const data = {
            token: this.token
        };

        this.externalService.validateAgilizeAttendanceToken(data, (error, resp) => {
            this.loading = false;

            if (error) {
                this.isValidToken = false;
                return;
            }

            this.isValidToken = true;

            const patientInfo = {
                ...resp
            };

            delete patientInfo.addressFound;
            delete patientInfo.companyName;

            this.companyName = resp.companyName;

            if (resp.addressFound) {
                this.addressFound = resp.addressFound;
            }

            this.patient = {
                ...patientInfo
            };

            this.patient.born = this.patient.born ? moment(this.patient.born).format('DDMMYYYY') : '';

            if (this.patient.allergy) {
                this.patient.alergic = 'yes';
            } else {
                this.patient.alergic = 'no';
            }
        });
    }

    setBodyStyle() {
        document.querySelector('body').style.backgroundColor = '#E5E5E5';
    }

    toggleAllergy(type) {
        if (type === 'no') {
            this.patient.allergy = '';
        } else {
            this.patient.alergic = 'yes';
        }
    }

    isValid(data) {
        const now = moment();

        if (!data.name) {
            return this.alertMessageService.open('O nome é obrigatório.', null, 'warning');;
        }

        if (data.cpf && !ValidateCPF(data.cpf)) {
            this.errors.cpf = true;
            return this.alertMessageService.open('Preencha o cpf corretamente', null, 'warning');
        }

        if (data.born && moment(data.born, 'DDMMYYYY').isAfter(now)) {
            this.errors.born = true;
            return;
        }

        return true;
    }

    submit() {
        const data = Object.assign(this.patient, { token: this.token });

        if (!this.isValid(data)) {
            this.alertMessageService.open('Preencha os campos corretamente', null, 'warning');
            return;
        }

        if (!this.token) {
            return;
        }

        this.loading = true;

        this.externalService.agilizeAttendance(data, (error, resp) => {
            this.loading = false;
            this.formStep = false;

            if (error) {
                this.formStep = true;
                this.alertMessageService.open(error.message, null, 'error');
                return;
            }

            this.isValidToken = true;
        });
    }
}
