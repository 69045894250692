import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DeviceService, AttendanceService } from '../../../shared/services';

@Component({
    selector: 'app-confirm-attendance-page',
    templateUrl: './confirm-attendance-page.component.html',
    styleUrls: ['./confirm-attendance-page.component.scss']
})
export class ConfirmAttendancePageComponent implements OnInit {
    token = null;
    action = null;
    patient = null;
    loading: boolean = true;
    isValidToken: boolean = true;

    constructor (
        private route: ActivatedRoute,
        private attendanceService: AttendanceService,
        private deviceService: DeviceService
    ) {}

    ngOnInit() {
        this.token = this.route.snapshot.queryParams.token;

        if (this.deviceService.isWeb()) {
            this.setBodyStyle();
        }

        if (!this.token) {
            this.loading = false;
            this.isValidToken = false;

            return;
        }

        this.submit();
    }

    submit() {
        const data = {
            token: this.token
        };

        this.attendanceService.attendanceStatus(data, (error, resp) => {
            this.loading = false;

            if (error) {
                this.isValidToken = false;
                return;
            }

            this.isValidToken = true;
            this.patient = resp;
        });
    }

    setBodyStyle() {
        document.querySelector('body').style.backgroundColor = '#E5E5E5';
    }
}
